<template>
    <workspace-wrapper>
        <template #workspace>
            <live-roulette-workspace :round-data="roundData" :tickets="tickets"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import LiveRouletteWorkspace from "./LiveRouletteWorkspace.vue";

export default {
    props: {
        roundData: {
            type: Object,
            required: true,
            default() {
                return {
                    resultNumber: undefined,
                }
            }
        },
        tickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    bet: undefined,
                    win: undefined,
                    total: undefined,
                    player_id: undefined,
                }]
            }
        }
    },
    components: {
        WorkspaceWrapper,
        LiveRouletteWorkspace
    }
}
</script>

<style scoped>
</style>
